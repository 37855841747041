import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/reg.vue'),
    meta: {
      title: '注册就送积分'
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help.vue'),
    meta: {
      title: '视频帮助'
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
