import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible/flexible'
import { Form, Search, DropdownMenu, DropdownItem, List, Cell, CellGroup, PullRefresh, Tag, Button, Sticky, Step, Steps, ActionBar, ActionBarIcon, ActionBarButton, ActionSheet, Dialog, Field, Cascader, Popup, Radio, RadioGroup, Picker } from 'vant'
import 'vant/lib/index.css'

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).document.title = to.meta.title === undefined ? '掌纷享' : to.meta.title + ' - 掌纷享'
  next()
})
createApp(App).use(router).mount('#app')
